import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import "./styles/message.css";

const Message = () => {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		// Check if the user is on a mobile device
		const isMobile = window.innerWidth <= 1080;
		if (isMobile) {
			setIsVisible(true);
		}
	}, []);

	const handleClose = () => {
		setIsVisible(false);
	};

	const handleDesktopView = () => {
		const viewportMeta = document.querySelector('meta[name="viewport"]');
		if (viewportMeta) {
			viewportMeta.setAttribute("content", "width=1270");
		}
		handleClose();
	};

	if (!isVisible) {
		return null;
	}

	return (
		<div className="mobile-message-overlay">
			<div className="mobile-message">
				<p>Bienvenue sur mon portfolio ! Le site est en cours d'adaptation pour les téléphones et tablettes.</p>
				<div className="button-container">
					<button className="compris-button" onClick={handleClose}>
						<FontAwesomeIcon icon={faCheckCircle} /> Compris
					</button>
					<button className="desktop-version-link" onClick={handleDesktopView}>
						Voir la version ordinateur
					</button>
				</div>
			</div>
		</div>
	);
};

export default Message;
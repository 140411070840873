import { Routes, Route } from "react-router-dom";

import Homepage from "./pages/homepage";
import ProposDeMoi from "./pages/a-propos-de-moi";
import Projets from "./pages/projets";
import Etudes from "./pages/etudes";
import ReadEtude from "./pages/readEtude";
import Works from "./pages/works";
//import ReadWork from "./pages/readWork";
import Contact from "./pages/contact";
import Notfound from "./pages/404";
import Message from "./pages/message";

import "./app.css";

function App() {
	return (
		<div className="App">
			<Routes>
				<Route path="/" element={<Homepage />} />
				<Route path="/a-propos-de-moi" element={<ProposDeMoi />} />
				<Route path="/projets" element={<Projets />} />
				<Route path="/etudes" element={<Etudes />} />
				<Route path="/etudes/:slug" element={<ReadEtude />} />
				<Route path="/works" element={<Works />} />
				{/*<Route path="/works/:slug" element={<ReadWork />} />*/}
				<Route path="/contact" element={<Contact />} />
				<Route path="*" element={<Notfound />} />
			</Routes>
			<Message />
		</div>
	);
}

export default App;
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";

import INFO from "../data/user";
import Etudes from "../data/etudes";

import "./styles/readEtude.css";

let EtudeStyle = styled.div``;

const ReadEtude = () => {
	const navigate = useNavigate();
	let { slug } = useParams();

	const etude = Etudes[slug - 1];

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [etude]);

	EtudeStyle = styled.div`
		${etude().style}
	`;

	return (
		<React.Fragment>
			<Helmet>
				<title>{`${etude().title} | ${INFO.main.title}`}</title>
				<meta name="description" content={etude().description} />
				<meta name="keywords" content={etude().keywords.join(", ")} />
			</Helmet>

			<div className="page-content">
				<NavBar />

				<div className="content-wrapper">
					<div className="read-etude-logo-container">
						<div className="read-etude-logo">
							<Logo width={46} />
						</div>
					</div>

					<div className="read-etude-container">
						<div className="read-etude-back">
							<img
								src="../back-button.png"
								alt="back"
								className="read-etude-back-button"
								onClick={() => navigate(-1)}
							/>
						</div>

						<div className="read-etude-wrapper">
							<div className="read-etude-date-container">
								<div className="read-etude-date">
									{etude().date}
								</div>
							</div>

							<div className="title read-etude-title">
								{etude().title}
							</div>

							<div className="read-etude-body">
								<EtudeStyle>{etude().body}</EtudeStyle>
							</div>
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default ReadEtude;

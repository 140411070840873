import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

import Logo from "../components/common/logo";
import Footer from "../components/common/footer";
import NavBar from "../components/common/navBar";
import Etude from "../components/homepage/etude";
import Work from "../components/homepage/work";
import AllProjets from "../components/projets/allProjets";

import INFO from "../data/user";
import SEO from "../data/seo";
import Etudes from "../data/etudes";
import WorksData from "../data/exp-pros";

import "./styles/homepage.css";
import { faBriefcase, faSchool, faProjectDiagram } from "@fortawesome/free-solid-svg-icons";

import Card from "../components/common/card"

const Homepage = () => {
	const [stayLogo, setStayLogo] = useState(false);
	const [logoSize, setLogoSize] = useState(80);
	const [oldLogoSize, setOldLogoSize] = useState(80);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const handleScroll = () => {
			let scroll = Math.round(window.pageYOffset, 2);

			let newLogoSize = 80 - (scroll * 4) / 10;

			if (newLogoSize < oldLogoSize) {
				if (newLogoSize > 40) {
					setLogoSize(newLogoSize);
					setOldLogoSize(newLogoSize);
					setStayLogo(false);
				} else {
					setStayLogo(true);
				}
			} else {
				setLogoSize(newLogoSize);
				setStayLogo(false);
			}
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [logoSize, oldLogoSize]);

	const currentSEO = SEO.find((item) => item.page === "home");

	const logoStyle = {
		display: "flex",
		position: stayLogo ? "fixed" : "relative",
		top: stayLogo ? "3vh" : "auto",
		zIndex: 999,
		border: stayLogo ? "1px solid white" : "none",
		borderRadius: stayLogo ? "50%" : "none",
		boxShadow: stayLogo ? "0px 4px 10px rgba(0, 0, 0, 0.25)" : "none",
	};

	return (
		<React.Fragment>
			<Helmet>
				<title>{INFO.main.title}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="home" />
				<div className="content-wrapper">
					<div className="homepage-logo-container">
						<div style={logoStyle}>
							<Logo width={logoSize} link={false} />
						</div>
					</div>

					<div className="homepage-container">
						<div className="homepage-first-area">
							<div className="homepage-first-area-left-side">
								<div className="title homepage-title">
									{INFO.homepage.title}
								</div>

								<div className="subtitle homepage-subtitle">
									{INFO.homepage.description}
								</div>
							</div>

							<div className="homepage-first-area-right-side">
								<div className="homepage-image-container">
									<div className="homepage-image-wrapper">
										<img
											src="photo.jpg"
											alt="a_propos_de_moi"
											className="homepage-image"
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="homepage-socials">
							<a
								href={INFO.socials.github}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faGithub}
									className="homepage-social-icon"
								/>
							</a>
							<a
								href={`mailto:${INFO.main.email}`}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faMailBulk}
									className="homepage-social-icon"
								/>
							</a>
						</div>

						<div className="homepage-projets">
							<div className="subtitle1 homepage-subtitle1">
								<FontAwesomeIcon icon={faProjectDiagram} />&nbsp;&nbsp;Projets
							</div>
							<AllProjets />
						</div>

						<div className="homepage-after-title">
							<div className="homepage-etudes">
								<div className="subtitle2 homepage-subtitle2">
									&nbsp;<FontAwesomeIcon icon={faSchool} />&nbsp;&nbsp;{INFO.etudes.title}
								</div>

								{Etudes.map((etude, index) => (
									<div
										className="homepage-etude"
										key={(index + 1).toString()}
									>
										<Etude
											key={(index + 1).toString()}
											date={etude().date}
											title={etude().title}
											description={etude().description}
											link={"/etudes/" + (index + 1)}
										/>
									</div>
								))}
							</div>

							<div className="homepage-works">
								<Card
									icon={faBriefcase}
									title="Expériences professionnelles"
									body={
										<div className="works-body">
											{WorksData.map((work, index) => (
												<Work
													key={index}
													date={work().date}
													title={work().title}
													subtitle={work().subtitle}
													logo={work().logo}
													description={work().description}
													link={"/works/" + (index + 1)}
												/>
											))}
										</div>
									}
								/>
							</div>
						</div>

						<div className="page-footer">
							<Footer />
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Homepage;

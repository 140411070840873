import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import "./style/works.css";

const Work = (props) => {
	const { date, title, subtitle, description, logo, link } = props;

	return (
		<React.Fragment>
			<div className="work">
				<div className="work-left-side">
					<div className="work-date">{date}</div>
				</div>

				<a href={link} target="_blank" rel="noopener noreferrer">
					<div className="work-right-side">
						<div className="work-header">
							<div>
								<div className="work-title">{title}</div>
								<div className="work-subtitle">{subtitle}</div>
							</div>
							<div className="work-logo">
								<img src={logo} alt={title} className="work-image" />
							</div>
						</div>
						<div className="work-description">
							{Array.isArray(description) ? (
								description.map((line, index) => (
									<p key={index}>{line}</p>
								))
							) : (
								<p>{description}</p>
							)}
						</div>
						<div className="work-link">
							Détails{" "}
							<FontAwesomeIcon
								style={{ fontSize: "10px" }}
								icon={faChevronRight}
							/>
						</div>
					</div>
				</a>
			</div>
		</React.Fragment>
	);
};

export default Work;
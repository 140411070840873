import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Etude from "../components/etudes/etudes";

import INFO from "../data/user";
import SEO from "../data/seo";
import mesEtudes from "../data/etudes";

import "./styles/etudes.css";

const Etudes = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "etudes");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Etudes | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="etudes" />
				<div className="content-wrapper">
					<div className="etudes-logo-container">
						<div className="etudes-logo">
							<Logo width={46} />
						</div>
					</div>

					<div className="etudes-main-container">
						<div className="title etudes-title">
							{INFO.etudes.title}
						</div>

						<div className="subtitle etudes-subtitle">
							{INFO.etudes.description}
						</div>

						<div className="etudes-container">
							<div className="etudes-wrapper">
								{mesEtudes.map((etude, index) => (
									<div
										className="etudes-etude"
										key={(index + 1).toString()}
									>
										<Etude
											key={(index + 1).toString()}
											date={etude().date}
											title={etude().title}
											description={etude().description}
											link={"/etudes/" + (index + 1)}
										/>
									</div>
								))}
							</div>
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Etudes;

const INFO = {
	main: {
		title: "Portfolio",
		name: "Jérémy TO",
		email: "contact@jeremy-to.tech",
		phone: "06 18 81 79 56",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/to-jeremy",
		linkedin: "https://www.linkedin.com/in/jeremy-to",
	},

	homepage: {
		title: "Futur étudiant en cycle d'ingénieur informatique à l'ESIEA.",
		description:
			"Je suis un étudiant passionné par le domaine du développement de logiciels et particulièrement des jeux vidéos. J'aimerai faire de ma passion, mon futur métier ! Ce portfolio permet de me présenter et de présenter mon parcours académique et professionnel.",
	},

	a_propos_de_moi: {
		title: "Jérémy TO",
		description:
			"Je m'appelle Jérémy TO, j'ai 22 ans, et je réside à Vitry-sur-Seine. Je suis passionné par le développement de logiciels, d'applications web, je vais prochainement intégrer le cycle d'ingénieur informatique à l'ESIEA d'Ivry-sur-Seine. Mon objectif professionnel est de devenir un Ingénieur Développeur Full Stack. Mon parcours académique et mon engagement dans le domaine informatique me préparent à relever les défis passionnants du monde du développement informatique.",
	},

	etudes: {
		title: "Parcours d'études",
		description:
			"Voici mon parcours d'études personnel.",
	},

	works: {
		title: "Expériences professionnelles",
		description:
			"Voici mes expériences professionnelles.",
	},

	projets: [
		{
			title: "Job Application (client)",
			date: "05 juin 2024",
			description:
				"Interface React pour l'application web ToDo List des candidatures.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "Cliquez dessus",
			link: "https://github.com/to-jeremy/job-application-frontend",
		},

		{
			title: "Job Application (serveur)",
			date: "05 juin 2024",
			description:
				"Server Node.js pour l'application web ToDo List des candidatures.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "Cliquez dessus",
			link: "https://github.com/to-jeremy/job-application-backend",
		},

		{
			title: "Bot discord",
			date: "26 novembre 2023",
			description:
				"Découverte à la création d'un bot Discord en Python.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "Cliquez dessus",
			link: "https://github.com/to-jeremy/Bot_Discord",
		},

		{
			title: "Tâches Gourmandes",
			date: "17 décembre 2022",
			description:
				"Projet d'application web pour la gestion de recettes fait en Bachelor Développement & Data sur mon temps personnel.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "Lien désactivé",
			link: "#",
		},

		{
			title: "Jeu de rôle d'un donjon en Java",
			date: "10 mars 2023",
			description:
				"Jeu de rôle dans un donjon avec : cinq salles, trois personnages, trois classes, des équipements et objets, des ennemis par salle et un boss final.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/java/java.png",
			linkText: "Cliquez dessus",
			link: "https://github.com/to-jeremy/JDR_java",
		},

		{
			title: "Application ToDoList en Java",
			date: "17 novembre 2022",
			description:
				"Petit projet d'une application de ToDoList pour Android fait avec Android Studio.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/java/java.png",
			linkText: "Cliquez dessus",
			link: "https://github.com/to-jeremy/todo_list",
		},

		{
			title: "Front-end d'une petite application web en React",
			date: "25 janvier 2023",
			description:
				"Développement web frontal se connectant au back-end que j'ai fait en Node.js.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "Cliquez dessus",
			link: "https://github.com/to-jeremy/React",
		},

		{
			title: "Back-end d'une petite application web en Node.js",
			date: "25 janvier 2023",
			description:
				"Couche d'accès aux données avec une base de donnée en MongoDB pour un mini site web.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "Cliquez dessus",
			link: "https://github.com/to-jeremy/Nodejs_mongo",
		},

		{
			title: "Site web avec authentification en Node.js",
			date: "octobre 2022",
			description:
				"Développement d'un site web fait en Node.js et qui se connecte avec une base de données MySQL.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "Cliquez dessus",
			link: "https://github.com/to-jeremy/Nodejs_mysql",
		},
	],
};

export default INFO;
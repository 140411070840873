import React from "react";

import Projet from "./projet";

import INFO from "../../data/user";

import "./styles/allProjets.css";

const AllProjets = () => {
	return (
		<div className="all-projets-container">
			{INFO.projets.map((projet, index) => (
				<div className="all-projets-project" key={index}>
					<Projet
						logo={projet.logo}
						date={projet.date}
						title={projet.title}
						description={projet.description}
						linkText={projet.linkText}
						link={projet.link}
					/>
				</div>
			))}
		</div>
	);
};

export default AllProjets;

import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import AllProjets from "../components/projets/allProjets";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/projets.css";

const Projets = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "projets");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Projets | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="projets" />
				<div className="content-wrapper">
					<div className="projets-logo-container">
						<div className="projets-logo">
							<Logo width={46} />
						</div>
					</div>
					<div className="projets-container">
						<div className="title projets-title">
							Mes projets d'études et personnels.
						</div>

						<div className="subtitle projets-subtitle">
							J'ai travaillé sur divers projets au fil des années
							et je suis fier des progrès que j'ai accomplis.
							Collaborer avec d'autres personnes est un excellent
							moyen d'apprendre et de se développer, et je suis
							ouvert aux nouvelles idées et aux commentaires.
						</div>

						<div className="projets-list">
							<AllProjets />
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Projets;

const SEO = [
	{
		page: "home",
		keywords: ["Home", "Accueil"],
	},

	{
		page: "a_propos_de_moi",
		keywords: ["Présentation", "Détails"],
	},

	{
		page: "etudes",
		description:
			"Voici mon parcours d'études personnel.",
		keywords: ["Parcours", "Etudes"],
	},

	{
		page: "works",
		description:
			"Voici mes expériences professionnelles.",
		keywords: ["Experiences", "Professionnelles", "Entreprises"],
	},

	{
		page: "projets",
		keywords: ["Projets", "Personnel", "Etude"],
	},

	{
		page: "contact",
		keywords: ["Contact", "Informations"],
	},
];

export default SEO;

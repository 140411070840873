import React from "react";

function etude_1() {
	return {
		date: "Septembre 2019 - Juillet 2020",
		title: "Baccalauréat STI2D - Option Innovation Technologique et Éco-Conception",
		description:
			"Lycée : Jean Macé (Vitry-sur-Seine)",
		style: ``,
		keywords: [
			"Baccalauréat STI2D",
			"Option Innovation Technologique et Éco-Conception",
			"Jean Macé",
			"Jérémy TO",
		],
		body: (
			<React.Fragment>
				<h1>Mention : Assez bien</h1>

				<h2>Lycée : Jean Macé</h2>
				<h2>Ville : Vitry-sur-Seine</h2>

				<p>Le bac technologique sciences et technologies de l'industrie et du développement durable (STI2D)
					développe des compétences pour toutes celles et tous ceux qui veulent imaginer, créer, concevoir
					et réaliser les produits de demain.</p>

				<p>L'enseignement spécifique "innovation technologique et écoconception (ITEC)"  de la spécialité
					"ingénierie, innovation et développement durable" aborde l’étude et la recherche de
					solutions constructives innovantes relatives aux structures matérielles des produits en intégrant
					toutes les dimensions de la compétitivité industrielle. Il apporte les compétences nécessaires à l’analyse,
					la conception et l’intégration d’un produit dans son environnement dans une démarche de développement durable.</p>
			</React.Fragment>
		)
	};
}

function etude_2() {
	return {
		date: "Septembre 2020 - Septembre 2022",
		title: "DUT Réseaux et Télécommunications",
		description:
			"IUT : IUT de Créteil-Vitry (campus de Vitry)",
		keywords: [
			"DUT Réseaux et Télécommunications",
			"UPEC",
			"IUT",
			"Jérémy TO",
		],
		body: (
			<React.Fragment>
				<h2>IUT : IUT de Créteil-Vitry (UPEC)</h2>
				<h2>Ville : Vitry-sur-Seine</h2>

				<p>Le diplômé du DUT spécialité R&T (Réseaux et Télécommunications) acquiert des connaissances
					en administration des réseaux IP (Internet), en création d’outils et d’applications informatiques
					pour les réseaux et télécommunications.</p>

				<p>Réalisation d'un stage de deux mois et demi chez TERIA - Exagone.</p>
			</React.Fragment>
		)
	};
}

function etude_3() {
	return {
		date: "Septembre 2022 - Juillet 2023",
		title: "Bachelor Développement et Data en alternance",
		description:
			"École : Efrei Paris",
		keywords: [
			"Bachelor Développement et Data",
			"Alternance",
			"Efrei",
			"Jérémy TO",
		],
		body: (
			<React.Fragment>
				<h2>École : EFREI Paris</h2>
				<h2>Ville : Villejuif</h2>

				<h3>Entreprise d'alternance : AI Pro Management</h3>

				<p>Le diplome du Bachelor m'a formé aux développements d'applications et aux projets
					en mode agile. J'ai pu acquérir les bases des langages de programmation suivantes :
				</p>
				<ul>
					<li>Node.JS</li>
					<li>React.JS</li>
					<li>Java</li>
					<li>Python</li>
				</ul>

				<p>Bases de données :</p>
				<ul>
					<li>MySQL</li>
					<li>MongoDB</li>
				</ul>
			</React.Fragment>
		)
	};
}

function etude_4() {
	return {
		date: "Septembre 2023 - Juillet 2024",
		title: "Master 1 Informatique - Parcours SAR",
		description:
			"Université : Sorbonne Université",
		keywords: [
			"Master Informatique",
			"Systèmes et Applications Répartis",
			"SU",
			"Jérémy TO",
		],
		body: (
			<React.Fragment>
				<h2>Université : Sorbonne Université</h2>
				<h2>Campus : Pierre-et-Marie-Curie</h2>
				<h2>Ville : Paris 5ème</h2>
			</React.Fragment>
		)
	};
}

function etude_5() {
	return {
		date: "Septembre 2024 - Septembre 2027",
		title: "Cycle d'ingénieur en informatique",
		description:
			"École : ESIEA à Ivry-sur-Seine",
		keywords: [
			"Cycle ingénieur",
			"Informatique",
			"ESIEA",
			"Jérémy TO",
		],
		body: (
			<React.Fragment>
				<h2>École : ESIEA</h2>
				<h2>Ville : Ivry-sur-Seine</h2>
			</React.Fragment>
		)
	};
}

const Etudes = [etude_5, etude_4, etude_3, etude_2, etude_1];

export default Etudes;
